import _ from "lodash/fp";
import React from "react";
import jwt from "jsonwebtoken";
import { connect } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Accordion, Header, Icon, Image } from "semantic-ui-react";
import { AuthMethodsLoader, LoginButtons } from "@truedat/auth/components";
import { Alert } from "@truedat/core/components";
import { SEARCH } from "@truedat/core/routes";
import logo from "assets/moeve-quimica-login.png";
import LoginForm from "./LoginForm";

const LoginHeader = () => (
  <Header
    inverted
    as="h1"
    icon
    textAlign="center"
    style={{ margin: "0px -70px 26px -60px" }}
  >
    <Header.Content className="unselectable">
      <Image src={logo} style={{ height: "auto", margin: "180px 0 0px 0" }} />
      <h2 className="moeve-motto" aria-label="We are Chemicals">
        <FormattedMessage id="motto.moeve.chemicals" defaultMessage="We are Chemicals" />  
      </h2>     
    </Header.Content>
  </Header>
);

const mainWrapper = {
  backgroundColor: "rgb(3, 125, 186)",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  position: "absolute",
  left: 0,
  right: 0
};

const viewStyle = {
  width: "310px",
  display: "flex",
  flexDirection: "column"
};

export class LoginView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { formVisible: false };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(e) {
    e.preventDefault();
    const { formVisible } = this.state;
    this.setState({ formVisible: !formVisible });
  }

  render() {
    const { formVisible } = this.state;
    return (
      <>
        <LoginHeader />
        <AuthMethodsLoader />
        <LoginButtons />
        <Alert />
        <Accordion>
          <Accordion.Title active={formVisible} onClick={this.handleToggle}>
            <Icon name="dropdown" />
            <FormattedMessage
              id="login.alternative"
              defaultMessage="Alternative login"
            />
          </Accordion.Title>
        </Accordion>
        {formVisible && <LoginForm />}
      </>
    );
  }
}

const resolveRoute = defaultRoute =>
  _.cond([
    [_.pathEq("state.from.pathname", "/"), () => defaultRoute],
    [_.has("state.from"), _.path("state.from")],
    [_.stubTrue, () => defaultRoute]
  ]);

const LoginOrRedirect = ({ token }) => {
  const location = useLocation();
  const currentTime = (new Date().getTime() + 1) / 1000;
  const validToken = token && jwt.decode(token).exp > currentTime;
  if (validToken) {
    const to = resolveRoute(SEARCH)(location);
    return <Redirect to={to} />;
  } else {
    return (
      <div style={mainWrapper}>
        <div style={viewStyle}>
          <LoginView />
        </div>
      </div>
    );
  }
};

const mapStateToProps = ({ authentication }) => ({
  token: authentication.token
});

export default connect(mapStateToProps)(LoginOrRedirect);

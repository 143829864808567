import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Button, Form } from "semantic-ui-react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Alert } from "@truedat/core/components";
import { login } from "@truedat/auth/routines";


export class LoginForm extends React.Component {
  static propTypes = {
    intl: PropTypes.object,
    auth_realm: PropTypes.string,
    login: PropTypes.func,
    loginSubmitting: PropTypes.bool
  };

  constructor(props) {
    super(props);
    const user = { user_name: "", password: "", remember: false };
    this.state = { user, touched: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event, data) {
    const { name, value, checked } = data;
    const user = Object.assign({}, this.state.user, {
      [name]: value || checked || ""
    });
    this.setState({ user, touched: true });
    event.preventDefault();
  }

  handleSubmit(event) {
    const { user } = this.state;
    this.props.login(user);
    event.preventDefault();
  }

  render() {
    const { auth_realm, loginSubmitting, intl } = this.props;
    const { formatMessage } = intl;
    const { user } = this.state;
    const valid = user.user_name !== "" && user.password !== "";
    return (
      
      <Form onSubmit={this.handleSubmit} style={{ maxWidth: "500px", marginTop:"1pc",borderRadius:"10pc"}}>
        <Form.Input
          name="user_name"
          icon="user"
          label={{
            children: formatMessage({ id: "login.form.username.label" }),
            style: { color: "#ffffff" }
          }}          
          iconPosition="left"
          placeholder={formatMessage({ id: "login.form.username.placeholder" })}
          autoComplete="off"
          onChange={this.handleChange}
          value={user.username}          
          required
        />
        <Form.Input
          name="password"
          type="password"
          label={{
            children: formatMessage({ id: "login.form.password.label" }),
            style: { color: "#ffffff" }
          }}
          placeholder={formatMessage({ id: "login.form.password.placeholder" })}
          icon="lock"
          iconPosition="left"
          onChange={this.handleChange}
          value={user.password}
          autoComplete="off"
          required
        />
        <Button
          style={{ width: "100%" }}
          className="ui button login alternative"
          loading={loginSubmitting && !auth_realm}
          disabled={(loginSubmitting && !auth_realm) || !valid}
        >
          <FormattedMessage id="login.form.actions.login" />
        </Button>
        <Alert />
      </Form>
    );
  }
}

const mapStateToProps = ({ authentication, loginSubmitting }) => ({
  auth_realm: authentication.auth_realm,
  loginSubmitting
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ login }, dispatch)
});

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(LoginForm);
